import { useTemplateGroupService } from "~/src/services/TemplateGroupService";
import {
  abortNavigation,
  createError,
  defineNuxtRouteMiddleware,
} from "#imports";

export default defineNuxtRouteMiddleware(async (to) => {
  const { useGetTemplateQuery } = useTemplateGroupService();
  const result = await useGetTemplateQuery(
    String(to.params.id),
    String(to.params.templateId)
  );

  if (to.params.templateId === "new") return true;
  else if (!result.data.value)
    return abortNavigation(
      createError({
        statusCode: 404,
        message: "Template not found",
      })
    );
});
